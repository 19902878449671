
import { Vue, Options } from "vue-class-component";
import MultiSelector from "@/components/MultiSelector.vue";

@Options({
  components: {
    MultiSelector,
  },
  methods: {
    printSelectedValue(items: any) {
      this.selectedValue = items;
    },
  },
  data() {
    return {
      items: [
        "3207891231",
        "3207891238",
        "3207891234",
        "3207891233",
        "3207891235",
        "3207891236",
        "3207891237",
        "3207891232",
        "3207891239",
      ],
      selectedValue: "",
    };
  },
})
export default class TestMultiSelector extends Vue {}
