import { render } from "./TestMultiSelector.vue?vue&type=template&id=408a7a08"
import script from "./TestMultiSelector.vue?vue&type=script&lang=ts"
export * from "./TestMultiSelector.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "408a7a08"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('408a7a08', script)) {
    api.reload('408a7a08', script)
  }
  
  module.hot.accept("./TestMultiSelector.vue?vue&type=template&id=408a7a08", () => {
    api.rerender('408a7a08', render)
  })

}

script.__file = "src/views/TestMultiSelector.vue"

export default script